
import  MeetingBase  from "../../MeetingBase";
import { Modal,Button } from 'react-bootstrap';
import { DropdownButton, Dropdown } from 'react-bootstrap';




class VideoSetting extends MeetingBase{
  
    constructor(){
        super();
        this.state = {      audioIn: [], audioOut: [], camera: []  
        }
        this.onSettingSaves = this.onSettingSaves.bind(this);
        this.onCameraChange = this.onCameraChange.bind(this);
        this.onMicSourceChange = this.onMicSourceChange.bind(this);

    }
  componentDidMount(){
        this.getAllDevices();
   
  }
  onSettingSaves(){

}
onMicSourceChange(eventKey ,event) {
    // console.log(eventKey);
    super.getMeetingRequest().audioInDevice = eventKey;
    super.getMeetingHandler().startLocalStream(false,true);
  }
  
  onCameraChange(eventKey ,event) {
    // console.log(eventKey);
    super.getMeetingRequest().cameraDevice = eventKey;
    super.getMeetingHandler().startLocalStream(true,false);

  }
  async getAllDevices() {
    let audioIn = await super.getMeetingHandler().getAudioInDevices();
    let audioOut = await super.getMeetingHandler().getAudioOutDevices();
    let camera = await super.getMeetingHandler().getCameraDevices();
    this.state = { audioIn: [], audioOut: [], speakerOut: [] }

    this.setState({ audioIn: audioIn, audioOut: audioOut, camera: camera });

  }
  
  render()
  {
    var audioInDropDownItems = []
    for (let audio of this.state.audioIn) {
      audioInDropDownItems.push(<Dropdown.Item key={audio.id} eventKey={audio.id} >{audio.label}</Dropdown.Item>);
    }
  
    var cameraDropDownItems = []
    for (let camera of this.state.camera) {
      cameraDropDownItems.push(<Dropdown.Item key={camera.id} eventKey={camera.id} >{camera.label}</Dropdown.Item>);
    }

    return <div>
    <Modal.Header closeButton>
        <Modal.Title>Change Device</Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <div className="vanicall">
        <div className="Vdchat" style={{textAlign : "center"}}>
        <DropdownButton id="dropdown-basic-button" title="Microphone" onSelect={this.onMicSourceChange}>
          {audioInDropDownItems}
        </DropdownButton>
        </div>
        <div className="Vdchat" style={{textAlign : "center"}}>
          <div className="chckbx">
          <DropdownButton id="dropdown-basic-button" title="Camera" onSelect = {this.onCameraChange}>
          {cameraDropDownItems}
        </DropdownButton>
          </div>
        </div>
        
      </div>	

      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={this.hideSetting}>
          Close
        </Button>
        <Button variant="primary" onClick={this.onSettingSaves}>
          Save Changes
        </Button>
      </Modal.Footer> */}
  
</div>

}
}

export default VideoSetting;