
import  MeetingBase  from "../../MeetingBase";
import MeetingHandler from "../../MeetingHandler";
import React from 'react';
import Utility from "../../extra/Utility";
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Tooltip from "@material-ui/core/Tooltip";
import { Beforeunload } from 'react-beforeunload';
import Config from "../../extra/Config";
import { Person } from "@material-ui/icons";

class ShareLinkSection extends MeetingBase{
  
    constructor(){
        super();
        this.url = window.location.origin +"?roomId="+MeetingHandler.getInstance().getMeetingRequest().getRoomId;
        this.urlTextBox = React.createRef();
        this.onCopyButtonTapped = this.onCopyButtonTapped.bind(this);
        this.onMeetingStartTime = this.onMeetingStartTime.bind(this);
        this.state = {time : "00:00" , participantCount : 0};
        this.onUpdateTime = this.onUpdateTime.bind(this);
        this.callStartTimeStamp = (new Date()).getTime();
        this.onParticipantUpdated = this.onParticipantUpdated.bind(this);
        this.onUnload = this.onUnload.bind(this);
    }

    onCopyButtonTapped(){
       Utility.copyLink();
       toast('Copied to Clipboard' , {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          })
  
    }
    onMeetingStartTime(timeStamp){
      this.callStartTimeStamp = timeStamp;
      this.onUpdateTime();



     
    }

    onUpdateTime(){
      let myDuration = (new Date()).getTime() - this.callStartTimeStamp;
      if(myDuration > 1){
      
        myDuration  = myDuration / 1000;

        var  min = Math.floor(myDuration/(60))  
        var second =  Math.floor(myDuration)%60 ;
        if((min + "" ).length === 1 ){
          min = "0" + min;
        }
        if((second + "" ).length === 1 ){
          second = "0" + second;
        }
        this.setState({time : (min + ":" + second)});
      }
      setTimeout(this.onUpdateTime.bind(this), 1000);

    }
  componentDidMount(){
    super.addExtraListener('meetingStartTime', this.onMeetingStartTime)
    
    MeetingHandler.getInstance().subscribeToLocalEvent("onParticipantUpdated",this.onParticipantUpdated)
    super.getMeetingHandler().getMeetingStartTime();
   
  }

  componentWillUnmount(){
    super.removeExtraListener('meetingStartTime', this.onMeetingStartTime)
    MeetingHandler.getInstance().unSubscribeToLocalEvent('onParticipantUpdated', this.onParticipantUpdated)

  }
  
  onParticipantUpdated(participants){
    this.setState({participantCount : participants.length})

  }

  onUnload(){
    Utility.logEvent({
      category: 'Exit',
      action: 'Time : ' + this.state.time ,
      value: "userId : " + super.getMeetingRequest().getUserId
    });
  }
  
  
  render(){
  
    return <div style = {{margin:'10px 0px'}}>
      
<Beforeunload onBeforeunload={this.onUnload} />

    <div className="invite">
      <div className="input-group">
        <div className="input-group" style = {{display: 'flex' , alignItems :'center' , justifyContent : 'center'}}>
        <div className="row d-flex" style = {{width:'100%',  position:'relative', alignItems :'center' , justifyContent : 'space-around' , flexWrap:'nowrap'}}>
          {Config.isCopyLinkRequired(super.getMeetingRequest().appId ,  super.getMeetingRequest().isAdmin) ?
          <div className="box" style = {{margin: '10px'}}>
            <Tooltip title="Copy Invite">
            <button   onClick={this.onCopyButtonTapped} id="copyBtn" className="btn btn-primary" type="submit" style = {{zIndex:'1'}}>  <i className="fa fa-clone" aria-hidden="true" /></button>       
            </Tooltip>
          </div> : null}
          <div className="box" style = {{position:'relative' , margin:'10px'}}>
          <section className = 'justify_align_flex'>
          <section className="mainScreen_videoTimer justify_align_flex"  style = {{ padding : '10px' ,width:'fit-content' , zIndex:'0' }}>
            <div className="mainScreen_videoTimerWhiteOutline justify_align_flex" style = {{borderRadius : '100%' ,height:'10px' , width : '10px' , backgroundColor : 'white', paddingRight : '10px' , position:'relative' , overflow:'hidden'}}>
              <div className="mainScreen_videoTimerRed" style = {{position : 'absolute' ,borderRadius : '100%' ,height:'5px' , width : '5px' , backgroundColor : 'red' , top:'3px' , left:'3px'}}>&nbsp;</div>
            </div>
            <div className="mainScreen_TimerTime" style = {{cursor:'default'}}>
              {this.state.time}
            </div>
          </section>
          </section>
          </div>
          <div className="box" style = {{margin: '10px'}}>

          <section className = 'attendees justify_align_flex' style = {{padding : '10px' , cursor:'default' , backgroundColor : 'rgb(0,123,255)' , borderRadius : '10px' , color : 'white' , zIndex : '1'}}>
            <div className = 'attendees_txt' style = {{paddingRight : '10px'}}><Person /> </div>
            <div className = 'attendees_cnt' style = {{fontWeight : 'bolder'}}>              {this.state.participantCount}
</div>
          </section>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>


}
}


export default ShareLinkSection;