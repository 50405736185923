
import  MeetingBase  from "../../MeetingBase";
import MeetingHandler from "../../MeetingHandler";
import Participant from "./Participant";
class ParticipatsPanel extends MeetingBase{
  
  constructor(){
    super()
    var allParticipants = MeetingHandler.getInstance().getParticipants();
    this.state = {participants : allParticipants}
  }


  componentDidMount(){

  }
  
  
  render()
  {
    return <div id="menu1" className="tab-pane fade active show"  style = {{overflowY:'scroll' ,overflowX:'hidden', height:'55vh'}}><br />  
    
   
    {this.state.participants.map((participant, index) => (
    <Participant key={participant.userId}   participant={participant} />
  ))}

    

  </div>
  
  


}
}

export default ParticipatsPanel;