import  MeetingBase  from "../MeetingBase";
import  Player  from "./Player";
import  InformationForm  from "./InformationForm";
import Utility from "../extra/Utility";

class StartMeeting extends MeetingBase{
  constructor(){
    super();
  }
 
  componentDidMount(){
    super.getMeetingRequest();
    super.getMeetingHandler().init();
  }

  
  
  componentWillUnmount(){

  }
  
  render(){
  
    return  <section className="main_wrapper" style = {{minHeight:'90vh'}}>
  <div className="container">
    <div className="mainboxes" style = {{height:'100%'}}>
      <div className="row" style = {{height:'100%'}}>
        <Player></Player>
        <InformationForm></InformationForm>
      </div>
    </div>
  </div>
</section>

}
}



export default StartMeeting;