import MeetingBase from "../../MeetingBase";
import React from "react";
import { Beforeunload } from "react-beforeunload";
import ChatIcon from "@material-ui/icons/Chat";
import MeetingHandler from "../../MeetingHandler";
import AudioStream from "./AudioStream";
import VideoStream from "./VideoStream";
import SignalWifiOffIcon from "@material-ui/icons/SignalWifiOff";
import { isMobile, isMobileOnly } from "react-device-detect";
import Utility from "../../extra/Utility";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CallEndIcon from "@material-ui/icons/CallEnd";
import Tooltip from "@material-ui/core/Tooltip";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import StopScreenShareIcon from "@material-ui/icons/StopScreenShare";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExit from "@material-ui/icons/FullscreenExit";
import SettingsIcon from "@material-ui/icons/Settings";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Config from "../../extra/Config";
import VideoSetting from "./VideoSetting";
import { Modal, Button, CloseButton } from "react-bootstrap";
import RecordingUtilies from "../../extra/RecordingUtilies";
import GalleryViewBox from "./GalleryViewBox";
import { Close, Minimize, MoreVert, ThreeSixty } from "@material-ui/icons";

class VideoHolder extends MeetingBase {
  constructor() {
    super();

    this.allVideoStream = [];
    this.currentMainStreamData = null;
    this.mainVideoPlayer = React.createRef();
    this.videoConatiner = React.createRef();
    this.whiteboardFrame = React.createRef();
    this.videoAndBtnContainer = React.createRef();
    this.reordingCanvasRef = React.createRef();
    this.selfVideoForRecording = React.createRef();
    this.intialView = React.createRef();

    this.recordingCanvasContext = null;
    this.notReachableToast = null;
    this.isRecordingInProcess = false;
    this.isScreenshareOn = false;
    this.currentSpeaker = null;
    this.isSpeakerOff = false;
    this.showGalleryView = false;
    this.isWhiteboardVisible = false;
    this.currentTrack = null;
    this.localVideoTrack = null;
    this.localAudioTrack = null;
    this.isInPlayProcess = false;
    this.currentStream = null;

    this.recordingSvgImage =
      '<svg width="640" height="480" xmlns="http://www.w3.org/2000/svg"><g id="Layer_1"><text xml:space="preserve" text-anchor="start" font-family="sans-serif" font-size="24" id="svg_1" y="231" x="263.5" stroke-width="0" stroke="#000" fill="#000000">%name%</text></g></svg>';
    var initial = MeetingHandler.getInstance()
      .getMeetingRequest()
      .getUserData.name.charAt(0);

    this.state = {
      nameForMainVideo:
        MeetingHandler.getInstance().getMeetingRequest().getUserData.name,
      initial: initial,
      audioStreams: [],
      isMainVideoIsSS: false,
      videoStreams: [],
      audioMuted: MeetingHandler.getInstance()
        .getMeetingHandler()
        .isAudioMuted(),
      videoPaused: MeetingHandler.getInstance()
        .getMeetingHandler()
        .isVideoPaused(),
      mainVideoVisible: false,
      shoudlShowMirrorView: true,
      isScreenshareOn: this.isScreenshareOn,
      isSpeakerOff: this.isSpeakerOff,
      isWhiteboardVisible: this.isWhiteboardVisible,
      isInFullScreen: false,
      shouldStrechVideo: !isMobile,
      isVideoLoading: true,
      isStreamHasNetworkIssue: false,
      recordingToggle: false,
      isStreamMuted: false,
      showOptions: false,
      isChatWindowOpened: Config.byDefaultMessageOpen(
        super.getMeetingRequest().appId
      ),
      isNewMessage: false,
    };

    this.onSwitchToWhiteboard = this.onSwitchToWhiteboard.bind(this);
    this.startRecordingIfRequired = this.startRecordingIfRequired.bind(this);
    this.onTrack = this.onTrack.bind(this);
    this.onSpeakerChanged = this.onSpeakerChanged.bind(this);
    this.showOnMainVideoPlayer = this.showOnMainVideoPlayer.bind(this);
    this.handleMainVideoAndOtherVideoArray =
      this.handleMainVideoAndOtherVideoArray.bind(this);
    this.onUserLeft = this.onUserLeft.bind(this);
    this.checkAndAddDataToArray = this.checkAndAddDataToArray.bind(this);
    this.handleCameraButtonClick = this.handleCameraButtonClick.bind(this);
    this.endMeeting = this.endMeeting.bind(this);
    this.onSpeakerOnOff = this.onSpeakerOnOff.bind(this);
    this.whiteboardUrl = this.whiteboardUrl.bind(this);
    this.onWhiteBoardUnblocked = this.onWhiteBoardUnblocked.bind(this);
    this.onWhiteBoardBlocked = this.onWhiteBoardBlocked.bind(this);
    this.onCopyButtonTappedVideoHolder =
      this.onCopyButtonTappedVideoHolder.bind(this);
    this.shareScreen = this.shareScreen.bind(this);
    this.handleMuteButtonClick = this.handleMuteButtonClick.bind(this);
    this.fullScreen = this.fullScreen.bind(this);
    this.onScreenShareEnded = this.onScreenShareEnded.bind(this);
    this.onTrackEnded = this.onTrackEnded.bind(this);
    this.onScreenshareStream = this.onScreenshareStream.bind(this);
    this.onAudioVideoStatusUpdated = this.onAudioVideoStatusUpdated.bind(this);
    this.onWhiteBoardTapped = this.onWhiteBoardTapped.bind(this);
    this.refershTrack = this.refershTrack.bind(this);
    this.isCopyRequired = this.isCopyRequired.bind(this);
    this.isScreenshareRequired = this.isScreenshareRequired.bind(this);
    this.isWhiteboardRequired = this.isWhiteboardRequired.bind(this);
    this.onAudioBlocked = this.onAudioBlocked.bind(this);
    this.stopScreenshare = this.stopScreenshare.bind(this);
    this.onVideoBlocked = this.onVideoBlocked.bind(this);
    this.openSetting = this.openSetting.bind(this);
    this.hideSetting = this.hideSetting.bind(this);
    this.onNotReachable = this.onNotReachable.bind(this);
    this.onConnectionBack = this.onConnectionBack.bind(this);
    this.onReconnecting = this.onReconnecting.bind(this);
    this.onReconectionTimeout = this.onReconectionTimeout.bind(this);
    this.onStreamStatusUpdated = this.onStreamStatusUpdated.bind(this);
    this.onPermissionError = this.onPermissionError.bind(this);
    this.checkAndPerformAccordingToFullScreen =
      this.checkAndPerformAccordingToFullScreen.bind(this);
    this.onUserMuteOrEnded = this.onUserMuteOrEnded.bind(this);
    this.onUserUnmuted = this.onUserUnmuted.bind(this);
    this.drawOnCanvas = this.drawOnCanvas.bind(this);
    this.onPermissionApproved = this.onPermissionApproved.bind(this);
    this.handleShowOptions = this.handleShowOptions.bind(this);
    this.onNewMessage = this.onNewMessage.bind(this);
  }

  handleShowOptions() {
    this.setState({ showOptions: true });
    //console.log(this.showOptions)
  }

  startRecordingIfRequired() {
    if (this.isRecordingInProcess) {
      return;
    }
    // if(Config.isRecordinRequired(Utility.getAppId(),super.getMeetingRequest().isAdmin) === false){
    //   return
    // }
    if (this.reordingCanvasRef.current && this.mainVideoPlayer.current) {
      if (this.recordingCanvasContext === null) {
        this.recordingCanvasContext =
          this.reordingCanvasRef.current.getContext("2d");
        // this.recordingCanvasContext.translate(this.reordingCanvasRef.width / 2, this.reordingCanvasRef.height / 2);
        // this.recordingCanvasContext.scale(-1, 1);
      }

      this.isRecordingInProcess = true;
      if (
        this.selfVideoForRecording !== null &&
        this.localVideoTrack !== null
      ) {
        this.renderLocalVideoWithRecording(this.localVideoTrack);
      }
      this.drawOnCanvas();
      if (this.localAudioTrack !== null) {
        RecordingUtilies.getInstance().addAudioTrack(this.localAudioTrack);
      }

      for (let audioTrack of this.state.audioStreams) {
        RecordingUtilies.getInstance().addAudioTrack(audioTrack);
      }

      RecordingUtilies.getInstance().startRecording(
        this.reordingCanvasRef.current
      );
    }
  }

  startStopRecording() {
    if (this.state.recordingToggle) {
      console.log("Stop Recording");
      this.stopRecording(function () {});
    } else {
      console.log("Start Recording");
      this.startRecordingIfRequired();
    }
    this.setState({ recordingToggle: !this.state.recordingToggle });
  }

  stopRecording(completionBlock) {
    if (this.isRecordingInProcess) {
      this.isRecordingInProcess = false;

      RecordingUtilies.getInstance().stopRecording(completionBlock);
    } else {
      completionBlock();
    }
  }
  createTimeoutForDrawOnCanvas() {
    setTimeout(this.drawOnCanvas, 1000 / 30);
  }
  async drawOnCanvas() {
    if (
      this.state.isScreenshareOn &&
      this.selfVideoForRecording.current !== null &&
      this.recordingCanvasContext.current !== null
    ) {
      this.recordingCanvasContext.drawImage(
        this.selfVideoForRecording.current,
        0,
        0,
        720,
        480
      );
      this.createTimeoutForDrawOnCanvas();

      return;
    } else if (
      this.currentMainStreamData !== null &&
      this.currentMainStreamData.videoType !== "SS"
    ) {
      var nameToDraw = null;
      var videoPlayerToDraw = null;
      if (
        this.currentSpeaker !== null &&
        this.currentSpeaker.userId === super.getMeetingRequest().userId
      ) {
        if (
          this.currentSpeaker !== null &&
          this.currentSpeaker.isVideoEnable === false
        ) {
          nameToDraw = super.getMeetingRequest().userData.name;
        } else {
          if (this.selfVideoForRecording === null) {
            this.createTimeoutForDrawOnCanvas();
            return;
          }
          // if(this.selfVideoForRecording.current){

          // }
          videoPlayerToDraw = this.selfVideoForRecording.current;
        }
      } else {
        if (
          this.currentMainStreamData !== null &&
          this.currentMainStreamData.participant.isVideoEnable === false
        ) {
          nameToDraw = this.currentMainStreamData.participant.userData.name;
        } else {
          if (this.mainVideoPlayer === null) {
            this.createTimeoutForDrawOnCanvas();
            return;
          }
          videoPlayerToDraw = this.mainVideoPlayer.current;
        }
      }

      if (nameToDraw !== null && nameToDraw.length > 0) {
        this.drawOnCanvasWhenVideoOff(nameToDraw);
      } else if (videoPlayerToDraw !== null) {
        try {
          this.recordingCanvasContext.drawImage(
            videoPlayerToDraw,
            0,
            0,
            720,
            480
          );
        } catch (err) {
          console.log(err);
        }
      }
    } else if (this.mainVideoPlayer.current !== null) {
      this.recordingCanvasContext.drawImage(
        this.mainVideoPlayer.current,
        0,
        0,
        720,
        480
      );
    }
    this.createTimeoutForDrawOnCanvas();
  }

  drawOnCanvasWhenVideoOff(name) {
    this.recordingCanvasContext.clearRect(
      0,
      0,
      this.reordingCanvasRef.current.width,
      this.reordingCanvasRef.current.height
    );
    this.recordingCanvasContext.fillStyle = "white";
    this.recordingCanvasContext.fillRect(
      0,
      0,
      this.reordingCanvasRef.current.width,
      this.reordingCanvasRef.current.height
    );

    this.recordingCanvasContext.strokeStyle = "#3F87F1";
    this.recordingCanvasContext.beginPath();
    this.recordingCanvasContext.arc(
      this.reordingCanvasRef.current.width / 2,
      this.reordingCanvasRef.current.height / 2,
      40,
      0,
      Math.PI * 2
    );
    this.recordingCanvasContext.closePath();
    this.recordingCanvasContext.fill();
    this.recordingCanvasContext.fillStyle = "#3F87F1";
    this.recordingCanvasContext.fill();
    this.recordingCanvasContext.stroke();
    this.recordingCanvasContext.font = "50px Arial";
    this.recordingCanvasContext.fillStyle = "#fff";
    this.recordingCanvasContext.fillText(
      name.charAt(0),
      this.reordingCanvasRef.current.width / 2 - 12,
      this.reordingCanvasRef.current.height / 2 + 12
    );

    //Top Rectangle

    this.recordingCanvasContext.fillStyle = "#e0e0e0";
    this.recordingCanvasContext.beginPath();
    this.recordingCanvasContext.moveTo(75, 25);
    this.recordingCanvasContext.fillRect(20, 20, 90 + 10 * name.length, 60);
    this.recordingCanvasContext.beginPath();

    this.recordingCanvasContext.arc(50, 50, 20, 0, Math.PI * 2);
    this.recordingCanvasContext.fillStyle = "#3F87F1";
    this.recordingCanvasContext.fill();
    this.recordingCanvasContext.font = "25px Arial";
    this.recordingCanvasContext.fillStyle = "#fff";
    this.recordingCanvasContext.fillText(name.charAt(0), 44, 55);
    this.recordingCanvasContext.font = "20px Arial";
    this.recordingCanvasContext.fillStyle = "#000";
    this.recordingCanvasContext.fillText(name, 90, 55);
    this.recordingCanvasContext.closePath();
    this.recordingCanvasContext.stroke();
  }

  refershTrack(data) {
    if (data.kind === "video") {
      if (data.isLocalTrack && data.videoType === "SS") {
        this.renderLocalVideoWithRecording(data);
        return;
      } else if (data.isLocalTrack) {
        this.localVideoTrack = data;
        this.renderLocalVideoWithRecording(data);
      }

      for (var index = 0; index < this.allVideoStream.length; index++) {
        let track = this.allVideoStream[index];
        if (
          track.participant.userId === data.participant.userId &&
          track.videoType === data.videoType
        ) {
          this.allVideoStream.splice(index, 1);
          this.allVideoStream.splice(index, 0, data);
        }
      }
      if (
        this.currentMainStreamData != null &&
        this.currentMainStreamData.participant.userId ===
          data.participant.userId
      ) {
        this.currentMainStreamData = data;
        this.showOnMainVideoPlayer(data, true);
      }
    } else {
      if (data.isLocalTrack === true) {
        this.localAudioTrack = data;
      }
      if (this.isRecordingInProcess) {
        RecordingUtilies.getInstance().addAudioTrack(data);
      }
    }
  }
  onTrack(track) {
    if (track.kind === "audio") {
      if (track.isLocalTrack === true) {
        this.localAudioTrack = track;
        if (this.isRecordingInProcess) {
          RecordingUtilies.getInstance().addAudioTrack(track);
        }
        return;
      }

      var canAdd = true;
      for (let audioStream of this.state.audioStreams) {
        if (track.participant.userId === audioStream.participant.userId) {
          canAdd = false;
          if (track.track !== null) {
            audioStream.track = track.track;
          }
          break;
        }
      }
      if (canAdd) {
        track.extraData.isSpeakerOff = this.isSpeakerOff;
        this.state.audioStreams.push(track);
        this.setState({ audioStreams: this.state.audioStreams });
        if (this.isRecordingInProcess) {
          RecordingUtilies.getInstance().addAudioTrack(track);
        }
      }
    } else {
      this.checkAndAddDataToArray(track);
    }
  }

  onCopyButtonTappedVideoHolder() {
    Utility.copyLink();
    toast("Copied to Clipboard", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });

    this.setState({ showOptions: false });
  }

  onSpeakerChanged(data) {
    if (
      (this.allVideoStream.length > 0 &&
        this.allVideoStream[0].videoType === "SS") ||
      this.isScreenshareOn
    ) {
      super.getMeetingHandler().optimizeStream({ makeThumbVideo: true });
      return;
    }
    let speakerUserId = data["speakerUserId"];
    console.log("SpeakerId " + speakerUserId);

    this.handleOptimizeStream(speakerUserId);
    this.currentSpeaker = data.participant;
    let selfUserId = super.getMeetingRequest().getUserId;
    if (speakerUserId === selfUserId) {
      return;
    }

    for (var index = 0; index < this.allVideoStream.length; index++) {
      let streamData = this.allVideoStream[index];
      if (speakerUserId === streamData.participant.userId) {
        this.allVideoStream.splice(index, 1);
        this.allVideoStream.splice(0, 0, streamData);
        this.handleMainVideoAndOtherVideoArray();
        break;
      }
    }
  }

  handleOptimizeStream(speakerUserId) {
    if (speakerUserId === null) {
      return;
    }
    if (super.getMeetingHandler().getParticipants.length < 3) {
      super.getMeetingHandler().optimizeStream({ makeThumbVideo: false });
      return;
    }
    // if (super.getMeetingHandler().getParticipants.length  === 1) {
    //   super.getMeetingHandler().optimizeStream({ "makeThumbVideo": false })
    //   return;
    // }

    let selfUserId = super.getMeetingRequest().getUserId;
    if (speakerUserId === selfUserId) {
      super.getMeetingHandler().optimizeStream({ makeThumbVideo: false });
      return;
    }
    super.getMeetingHandler().optimizeStream({ makeThumbVideo: true });
  }

  handleMainVideoAndOtherVideoArray() {
    if (this.isScreenshareOn || this.isWhiteboardVisible) {
      this.setState({ videoStreams: this.allVideoStream });
      return;
    }
    if (this.allVideoStream.length > 1) {
      this.setState({
        videoStreams: this.allVideoStream.slice(1, this.allVideoStream.length),
      });
    } else {
      this.setState({ videoStreams: [] });
    }
    if (this.allVideoStream.length === 0) {
      this.currentMainStreamData = null;
      this.setState({ mainVideoVisible: false, shoudlShowMirrorView: false });
    } else {
      this.showOnMainVideoPlayer(this.allVideoStream[0], false);
    }
  }

  showOnMainVideoPlayer(data, isForcefully) {
    if (
      isForcefully === false &&
      this.currentMainStreamData != null &&
      this.currentMainStreamData.participant.userId ===
        data.participant.userId &&
      this.currentMainStreamData.videoType === data.videoType
    ) {
      return;
    }

    if (
      this.currentTrack !== null &&
      data !== null &&
      data.track !== null &&
      this.currentTrack.id === data.track.id
    ) {
      return;
    }
    this.currentTrack = data.track;
    if (
      this.currentMainStreamData !== null &&
      this.currentMainStreamData.track !== null
    ) {
      try {
        this.currentMainStreamData.track.removeEventListener(
          "ended",
          this.onUserMuteOrEnded
        );
        this.currentMainStreamData.track.removeEventListener(
          "mute",
          this.onUserMuteOrEnded
        );
        this.currentMainStreamData.track.removeEventListener(
          "unmute",
          this.onUserUnmuted
        );
      } catch (err) {}
    }
    this.currentMainStreamData = data;
    if (data.track !== null) {
      try {
        data.track.addEventListener("ended", this.onUserMuteOrEnded);
        data.track.addEventListener("mute", this.onUserMuteOrEnded);
        data.track.addEventListener("unmute", this.onUserUnmuted);
      } catch (err) {}

      try {
        super.getMeetingHandler().resumeTrackInScreen(data);
        let mediStream = new MediaStream();
        mediStream.addTrack(data.track);
        this.playMediaStream(mediStream);
        this.setState({ isStreamMuted: data.track.muted });
      } catch (err) {
        console.log(err);
      }
    } else {
      this.mainVideoPlayer.current.pause();
    }
    if (data.cameraType === "back") {
      if (data.videoType === "SS") {
        this.setState({
          mainVideoVisible: true,
          shoudlShowMirrorView: false,
          isMainVideoIsSS: true,
        });
      } else {
        this.setState({
          mainVideoVisible:
            data.participant.isVideoEnable && data.track !== null,
          shoudlShowMirrorView: false,
          isMainVideoIsSS: false,
        });
      }
    } else {
      if (data.videoType === "SS") {
        this.setState({
          mainVideoVisible: true,
          shoudlShowMirrorView: false,
          isMainVideoIsSS: false,
        });
      } else {
        this.setState({
          mainVideoVisible:
            data.participant.isVideoEnable && data.track !== null,
          shoudlShowMirrorView: true,
          isMainVideoIsSS: false,
        });
      }
    }

    if (data.participant.userData !== null) {
      let name = data.participant.userData.name;
      var initial = name.charAt(0);
      this.setState({ nameForMainVideo: name, initial: initial });
    }
  }

  playMediaStream(mediStream) {
    if (this.isInPlayProcess) {
      this.currentStream = mediStream;
      return;
    }
    this.mainVideoPlayer.current.onplay = function () {
      this.isInPlayProcess = false;
      if (this.currentStream !== null) {
        this.playMediaStream(this.currentStream);
      }
    }.bind(this);
    if (mediStream.getVideoTracks().length > 0) {
      let videoTrack = mediStream.getVideoTracks()[0];
      this.setState({ isVideoLoading: videoTrack.muted });
    }

    this.mainVideoPlayer.current.load();

    this.mainVideoPlayer.current.srcObject = mediStream;
    this.mainVideoPlayer.current.play();
    this.isInPlayProcess = true;
    this.currentStream = null;
  }
  onUserMuteOrEnded() {
    // if(isEndded){
    //   console.log("trackended")
    // }
    // else{
    //   console.log("trackmute")
    // }
    this.setState({ isStreamMuted: true });
  }
  onUserUnmuted() {
    console.log("onUserUnmuted");
    this.setState({ isStreamMuted: false, isVideoLoading: false });
  }
  onTrackEnded(data) {
    let track = data;
    if (track.kind === "audio") {
      var audioTracks = this.state.audioStreams;
      for (var index = 0; index < audioTracks.length; index++) {
        let audioStreamData = audioTracks[index];
        if (track.trackId === audioStreamData.trackId) {
          audioTracks.splice(index, 1);
          break;
        }
      }
      this.setState({ audioStreams: audioTracks });
      RecordingUtilies.getInstance().removeOldTrackIfExist(data);
    } else {
      if (track.isLocalTrack && track.videoType === "SS") {
        this.unRenderLocalVideoForRecording(track);
        return;
      }
      for (var index = 0; index < this.allVideoStream.length; index++) {
        let streamData = this.allVideoStream[index];
        if (track.trackId === streamData.trackId) {
          this.allVideoStream.splice(index, 1);
          break;
        }
      }
      this.handleMainVideoAndOtherVideoArray();
      // this.handleOptimizeStream(this.currentSpeakerId)
    }
  }
  onUserLeft(data) {
    let userId = data.userId;

    for (var index = 0; index < this.allVideoStream.length; index++) {
      let streamData = this.allVideoStream[index];
      if (userId === streamData.participant.userId) {
        this.allVideoStream.splice(index, 1);
        index = index - 1;
      }
    }
    this.handleMainVideoAndOtherVideoArray();

    let allAudioStreams = this.state.audioStreams;
    for (var index = 0; index < allAudioStreams.length; index++) {
      let streamData = allAudioStreams[index];

      if (userId === streamData.participant.userId) {
        allAudioStreams.splice(index, 1);
        index = index - 1;
        break;
      }
    }

    this.setState({ audioStreams: allAudioStreams });
  }

  checkAndAddDataToArray(track) {
    if (track.isLocalTrack && track.videoType === "SS") {
      this.renderLocalVideoWithRecording(track);
      return;
    } else if (track.isLocalTrack) {
      this.localVideoTrack = track;
      this.renderLocalVideoWithRecording(track);
    }
    var canAdd = true;
    for (let videoStream of this.allVideoStream) {
      if (
        track.participant.userId === videoStream.participant.userId &&
        track.videoType === videoStream.videoType
      ) {
        canAdd = false;
        if (track.track !== null) {
          videoStream.track = track.track;
        }
        break;
      }
    }
    if (canAdd) {
      if (
        this.allVideoStream.length > 0 &&
        this.allVideoStream[0].videoType === "SS"
      ) {
        this.allVideoStream.splice(1, 0, track);
      } else if (track.videoType === "SS") {
        this.allVideoStream.splice(0, 0, track);
      } else if (
        track.participant.isAdmin &&
        Config.shouldFixAdminVideoOnTop(
          super.getMeetingRequest().appId,
          track.participant.isAdmin
        )
      ) {
        this.allVideoStream.splice(0, 0, track);
      } else if (
        this.allVideoStream.length > 1 &&
        this.allVideoStream[1].isLocalTrack
      ) {
        this.allVideoStream.splice(2, 0, track);
      } else if (
        this.allVideoStream.length > 0 &&
        Config.shouldFixAdminVideoOnTop(
          super.getMeetingRequest().appId,
          this.allVideoStream[0].isAdmin
        )
      ) {
        this.allVideoStream.splice(1, 0, track);
      } else {
        this.allVideoStream.splice(0, 0, track);
      }
    }
    this.handleMainVideoAndOtherVideoArray();
    // this.handleOptimizeStream(this.currentSpeakerId)
  }

  renderLocalVideoWithRecording(track) {
    if (
      track !== null &&
      this.isRecordingInProcess &&
      this.selfVideoForRecording.current !== null &&
      track.track !== null
    ) {
      this.selfVideoForRecording.current.srcObject = new MediaStream([
        track.track,
      ]);
      this.selfVideoForRecording.current.play();
    }
  }

  unRenderLocalVideoForRecording(track) {
    if (
      track !== null &&
      this.isRecordingInProcess &&
      this.selfVideoForRecording.current !== null &&
      track.track !== null
    ) {
      this.selfVideoForRecording.current.pause();
    }
    if (this.localVideoTrack !== null) {
      this.renderLocalVideoWithRecording(this.localVideoTrack);
    }
  }

  componentDidMount() {
    super.addExtraListener("permissionError", this.onPermissionError);
    super.addExtraListener("onTrack", this.onTrack);
    super.addExtraListener("onSpeakerChanged", this.onSpeakerChanged);
    super.addExtraListener("onTrackEnded", this.onTrackEnded);
    super.addExtraListener("screenShareEnded", this.onScreenShareEnded);
    super.addExtraListener("screenshareStream", this.onScreenshareStream);
    super.addExtraListenerWithForcefullyAdded(
      "onUserLeft",
      this.onUserLeft,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "refershTrack",
      this.refershTrack,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "audioVideoStatusUpdated",
      this.onAudioVideoStatusUpdated,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "audioBlocked",
      this.onAudioBlocked,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "audioUnblocked",
      this.onAudioUnblocked,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "videoBlocked",
      this.onVideoBlocked,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "videoUnblocked",
      this.onVideoUnblocked,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "messageBlocked",
      this.onMessageBlocked,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "messageUnblocked",
      this.onMessageUnblocked,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "whiteboardUnblocked",
      this.onWhiteBoardUnblocked,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "whiteboardBlocked",
      this.onWhiteBoardBlocked,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "switchToWhiteboard",
      this.onSwitchToWhiteboard,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "notReachable",
      this.onNotReachable,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "reconecting",
      this.onReconnecting,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "connectionBack",
      this.onConnectionBack,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "reconectionTimeout",
      this.onReconectionTimeout,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "streamStatusUpdated",
      this.onStreamStatusUpdated,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "permissionApproved",
      this.onPermissionApproved,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "onNewChatMessageReceived",
      this.onNewMessage,
      true
    );

    this.videoAndBtnContainer.current.addEventListener(
      "fullscreenchange",
      this.checkAndPerformAccordingToFullScreen
    );

    super.getMeetingHandler().getLocalStream();
    super.getMeetingHandler().startMeeting();
    // this.startRecordingIfRequired()
    // this.startRecordingIfRequired();
  }

  componentWillUnmount() {
    super.removeExtraListener(
      "audioVideoStatusUpdated",
      this.onAudioVideoStatusUpdated
    );

    super.removeExtraListener("onTrack", this.onTrack);
    super.removeExtraListener("onSpeakerChanged", this.onSpeakerChanged);
    super.removeExtraListener("onUserLeft", this.onUserLeft);
    super.removeExtraListener("screenShareEnded", this.onScreenShareEnded);
    super.removeExtraListener("onTrackEnded", this.onTrackEnded);
    super.removeExtraListener("screenshareStream", this.onScreenshareStream);
    super.removeExtraListener("refershTrack", this.refershTrack);
    super.removeExtraListener("audioBlocked", this.onAudioBlocked);
    super.removeExtraListener("messageBlocked", this.onMessageBlocked);
    super.removeExtraListener("messageUnblocked", this.onMessageUnblocked);
    super.removeExtraListener("audioUnblocked", this.onAudioUnblocked);
    super.removeExtraListener("videoBlocked", this.onVideoBlocked);
    super.removeExtraListener("videoUnblocked", this.onVideoUnblocked);
    super.removeExtraListener(
      "whiteboardUnblocked",
      this.onWhiteBoardUnblocked
    );
    super.removeExtraListener("whiteboardBlocked", this.onWhiteBoardBlocked);
    super.removeExtraListener("switchToWhiteboard", this.onSwitchToWhiteboard);
    super.removeExtraListener("notReachable", this.onNotReachable);
    super.removeExtraListener("reconecting", this.onReconnecting);
    super.removeExtraListener("connectionBack", this.onConnectionBack);
    super.removeExtraListener("reconectionTimeout", this.onReconectionTimeout);
    super.removeExtraListener(
      "streamStatusUpdated",
      this.onStreamStatusUpdated
    );
    super.removeExtraListener("permissionApproved", this.onPermissionApproved);

    super.removeExtraListener("onNewChatMessageReceived", this.onNewMessage);

    //this.videoAndBtnContainer.current.removeExtraListener("fullscreenchange" ,this.checkAndPerformAccordingToFullScreen )
  }

  onNewMessage() {
    console.log("onNewMessage");
    if (!this.state.isChatWindowOpened) {
      this.setState({ isNewMessage: true });
    }
  }
  onConnectionBack() {
    if (this.notReachableToast !== null) {
      toast.dismiss(this.notReachableToast);
    }
    toast("Connected", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  }

  onStreamStatusUpdated(track) {
    if (this.allVideoStream.length > 0) {
      if (
        track.participant.userId === this.allVideoStream[0].participant.userId
      ) {
        this.setState({ isStreamHasNetworkIssue: !track.isStremPresent });
      }
    }
  }
  onPermissionApproved() {
    let participant = super
      .getMeetingHandler()
      .participantByUserId(super.getMeetingRequest().userId);
    if (participant !== null) {
      if (participant.isVideoEnable) {
        this.setState({ videoPaused: false });
      } else {
        this.setState({ videoPaused: true });
      }
      if (participant.isAudioEnable) {
        this.setState({ audioMuted: false });
      } else {
        this.setState({ audioMuted: true });
      }
    }
  }
  onPermissionError() {}
  onReconectionTimeout() {
    if (this.notReachableToast !== null) {
      toast.dismiss(this.notReachableToast);
    }
    this.notReachableToast = toast(
      "Reconnection check timeout. Please Refersh and Join again",
      {
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      }
    );
  }
  onReconnecting() {
    if (this.notReachableToast !== null) {
      toast.dismiss(this.notReachableToast);
    }
    this.notReachableToast = toast("Connecting", {
      position: "top-center",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  }
  onNotReachable() {
    this.notReachableToast = toast("Connection Issue", {
      position: "top-center",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  }

  onMessageBlocked(adminParticipant) {
    toast("Your messaging is blocked", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  }

  onMessageUnblocked(adminParticipant) {
    toast("Your messaging is unblocked", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  }
  onWhiteBoardBlocked(adminParticipant) {
    let obj = { type: "CanEditOff" };
    this.whiteboardFrame.current.contentWindow.postMessage(obj, "*");
    toast("Your whiteboard is blocked", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  }
  onWhiteBoardUnblocked(adminParticipant) {
    let obj = { type: "CanEditOn" };
    this.whiteboardFrame.current.contentWindow.postMessage(obj, "*");
    toast("Your whiteboard is unblocked", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  }
  onAudioUnblocked(adminParticipant) {
    toast("Your audio is unblocked", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  }
  onVideoUnblocked(adminParticipant) {
    toast("Your video is unblocked", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  }

  onAudioBlocked(adminParticipant) {
    super.getMeetingHandler().muteUser(super.getMeetingRequest().getUserId);
    this.setState({ audioMuted: true });

    toast("Your audio is blocked", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  }

  onVideoBlocked(adminParticipant) {
    super.getMeetingHandler().pauseCamera(super.getMeetingRequest().getUserId);
    this.setState({ videoPaused: true });

    toast("Your video is blocked", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
    });
  }

  onAudioVideoStatusUpdated(participant) {
    let userId = participant.userId;
    if (
      this.allVideoStream.length > 0 &&
      this.allVideoStream[0].videoType !== "SS" &&
      userId === this.allVideoStream[0].participant.userId
    ) {
      if (this.state.mainVideoVisible === participant.isVideoEnable) {
        return;
      }
      this.setState({ mainVideoVisible: participant.isVideoEnable });
      if (
        this.allVideoStream[0].hasOwnProperty("track") &&
        this.allVideoStream[0].track !== null
      ) {
        this.allVideoStream[0].track.enabled = participant.isVideoEnable;
      }
    }
  }

  handleCameraButtonClick() {
    if (this.state.videoPaused) {
      super
        .getMeetingHandler()
        .resumeCamera(super.getMeetingRequest().getUserId)
        .then(
          function (output) {
            console.log(output);
            this.handleOutput(output, false, true);
          }.bind(this),
          function (error) {}
        );
    } else {
      super
        .getMeetingHandler()
        .pauseCamera(super.getMeetingRequest().getUserId)
        .then(
          function (output) {
            console.log(output);
            this.handleOutput(output, false, true);
          }.bind(this),
          function (error) {}
        );
    }
  }
  handleMuteButtonClick() {
    if (this.state.audioMuted) {
      super
        .getMeetingHandler()
        .unmute(super.getMeetingRequest().getUserId)
        .then(
          function (output) {
            console.log(output);
            this.handleOutput(output, true, false);
          }.bind(this),
          function (error) {}
        );
    } else {
      super
        .getMeetingHandler()
        .muteUser(super.getMeetingRequest().getUserId)
        .then(
          function (output) {
            this.handleOutput(output, true, false);
          }.bind(this),
          function (error) {}
        );
    }
  }

  handleOutput(output, isForAudio, isForVideo) {
    if (output !== null) {
      if (output.isSuccess) {
        this.onPermissionApproved();
        // if(isForAudio){
        //   this.setState({ audioMuted: !this.state.audioMuted })
        // }
        // if(isForVideo){
        //   this.setState({ videoPaused: !this.state.videoPaused })

        // }
      } else {
        Utility.showAlertOnOutputError(
          output,
          super.getMeetingRequest().getUserId
        );
      }
    }
  }

  openSetting() {
    this.setState({ showSetting: true, showOptions: false });
  }
  hideSetting() {
    this.setState({ showSetting: false });
  }

  onMicSourceChange(eventKey, event) {
    // console.log(eventKey);
    super.getMeetingRequest().audioInDevice = eventKey;
    super.getMeetingHandler().init();
  }

  endMeeting() {
    super.getMeetingHandler().endAndDestory();
    let thaankYouPage = Config.getThankYouPage(
      super.getMeetingRequest().appId,
      super.getMeetingRequest().isAdmin
    );

    this.stopRecording(function () {
      setTimeout(
        function () {
          let data = { status: true, type: "CallEnded" };
          window.parent.postMessage(data, "*");
          window.location.href = thaankYouPage;
        }.bind(this),
        300
      );
    });

    // window.location.href = "https://vanimeetings.com/Contact.html";

    // window.close()
  }

  shareScreen() {
    if (this.isScreenshareOn) {
      this.stopScreenshare();
    } else {
      if (
        this.allVideoStream.length > 0 &&
        this.allVideoStream[0].videoType === "SS"
      ) {
        alert("Only one person can share screen at a time");
        return;
      }

      super.getMeetingHandler().startScreenShare();
    }

    this.setState({ showOptions: false });
  }
  onScreenShareEnded() {
    this.isScreenshareOn = false;
    this.setState({ isScreenshareOn: this.isScreenshareOn });
    this.handleOptimizeStream(super.getMeetingRequest().getUserId);
    this.handleMainVideoAndOtherVideoArray();
  }
  stopScreenshare() {
    super.getMeetingHandler().stopScreenSharing();
  }
  onScreenshareStream() {
    this.isScreenshareOn = true;
    this.setState({ isScreenshareOn: this.isScreenshareOn });
    this.handleMainVideoAndOtherVideoArray();
  }
  fullScreen() {
    this.setState({ showOptions: false });
    if (!document.fullscreenElement) {
      this.setState({ isInFullScreen: true, shouldStrechVideo: false });

      this.videoAndBtnContainer.current.requestFullscreen().catch((err) => {
        alert(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        );
      });
    } else {
      document.exitFullscreen();
      this.setState({ isInFullScreen: false, shouldStrechVideo: !isMobile });
    }
  }

  checkAndPerformAccordingToFullScreen() {
    if (document.fullscreenElement) {
      this.setState({ isInFullScreen: true, shouldStrechVideo: false });
    } else {
      this.setState({ isInFullScreen: false, shouldStrechVideo: !isMobile });
    }
  }

  openWhiteboard(shouldInform) {
    this.isWhiteboardVisible = !this.isWhiteboardVisible;
    this.setState({ isWhiteboardVisible: this.isWhiteboardVisible });
    if (shouldInform) {
      super.getMeetingHandler().switchToWhiteboard();
    }
    this.handleMainVideoAndOtherVideoArray();
  }
  onSwitchToWhiteboard() {
    this.openWhiteboard(false);
  }
  onWhiteBoardTapped() {
    this.setState({ showOptions: false });
    this.openWhiteboard(super.getMeetingRequest().isAdmin);
  }

  onSpeakerOnOff() {
    this.isSpeakerOff = !this.isSpeakerOff;
    if (this.isSpeakerOff) {
      MeetingHandler.getInstance().emitLocalEvent(
        "onSpeakerOff",
        this.isSpeakerOff
      );
    } else {
      MeetingHandler.getInstance().emitLocalEvent(
        "onSpeakerOn",
        this.isSpeakerOff
      );
    }

    this.setState({ isSpeakerOff: this.isSpeakerOff });
  }

  isWhiteboardRequired() {
    return Config.isWhiteboardRequired(
      super.getMeetingRequest().appId,
      super.getMeetingRequest().isAdmin
    );
  }

  isRecordingRequired() {
    return Config.isRecordinRequired(
      super.getMeetingRequest().appId,
      super.getMeetingRequest().isAdmin
    );
  }
  isScreenshareRequired() {
    return Config.isScreenshareRequired(
      super.getMeetingRequest().appId,
      super.getMeetingRequest().isAdmin
    );
  }
  isCopyRequired() {
    return Config.isCopyLinkRequired(
      super.getMeetingRequest().appId,
      super.getMeetingRequest().isAdmin
    );
  }

  openCloseChatWindow() {
    MeetingHandler.getInstance().emitLocalEvent("openCloseChat", {
      shouldOpen: !this.state.isChatWindowOpened,
    });
    this.setState({
      isChatWindowOpened: !this.state.isChatWindowOpened,
      isNewMessage: false,
    });
  }

  whiteboardUrl() {
    // var baseUrl = "https://whiteboard.vanimeetings.com/?id=" + super.getMeetingRequest().roomId + "&userId="+ super.getMeetingRequest().userId ;
    var baseUrl =
      Config.whiteboardBaseUrl(Utility.getAppId()) +
      "?id=" +
      super.getMeetingRequest().roomId +
      "&userId=" +
      super.getMeetingRequest().userId;

    var canEdit = 0;
    var participant = super
      .getMeetingHandler()
      .participantByUserId(super.getMeetingRequest().getUserId);
    if (participant !== null) {
      if (participant.isWhiteboardBlockedByAdmin === false) {
        canEdit = 1;
      }
      baseUrl = baseUrl + "&canEdit=" + canEdit;
      return baseUrl;
    } else {
      if (super.getMeetingRequest().isAdmin) {
        canEdit = 1;
      }
      baseUrl = baseUrl + "&canEdit=" + canEdit;
      return baseUrl;
    }
    // console.log(participant)
    // if(participant.isWhiteboardBlockedByAdmin === false){
    //   canEdit = 1;
    // }
    // baseUrl = baseUrl + "&canEdit=" + canEdit;
    // return baseUrl;
  }
  render() {
    return (
      <div
        className="videopart vcall shadow p-3 "
        style={{ position: "relative" }}
      >
        {/* <Beforeunload onBeforeunload={(event) => event.preventDefault()} /> */}

        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          draggable
        ></ToastContainer>
        <div
          style={{ height: "100%", background: "#FFFFFF" }}
          ref={this.videoAndBtnContainer}
        >
          <div className="d-flex justify-content-between thumbTop">
            <div
              className={
                !this.state.isScreenshareOn && !this.state.isWhiteboardVisible
                  ? "vduser d-flex justify-content-around"
                  : "vduser d-flex justify-content-around hidden"
              }
              style={{ cursor: "default" }}
            >
              <div style={{ zIndex: "100", marginRight: "5px" }}>
                {this.isStreamHasNetworkIssue ? (
                  <SignalWifiOffIcon style={{ fontSize: 30 }} />
                ) : null}
              </div>
              <div
                className="inital"
                style={{ height: "30px", width: "30px", lineHeight: "30px" }}
              >
                {this.state.initial}
              </div>
              <div className="urname">
                <h4>{this.state.nameForMainVideo}</h4>
              </div>
            </div>
            <div
              className="rgvideo d-flex align-items-end flex-column"
              style={{ overflowY: "auto", overflowX: "hidden" }}
            >
              <ul className="rvlist" style={{ overflow: "auto" }}>
                {this.state.videoStreams.map((videoStream) => (
                  <VideoStream
                    key={videoStream.participant.userId}
                    videoStream={videoStream}
                  />
                ))}
              </ul>
            </div>
          </div>

          <div className="innerVideoDiv" ref={this.videoConatiner}>
            <div
              ref={this.intialView}
              className="centerInParenetInital"
              style={{ height: "90%", width: "99%", background: "#ffffff" }}
            >
              <div
                className={
                  this.state.isScreenshareOn
                    ? "inital centerInParenetInital  hidden"
                    : "inital centerInParenetInital  "
                }
                style={{
                  width: "80px",
                  height: "80px",
                  lineHeight: "80px",
                  fontSize: "30px",
                }}
              >
                {this.state.initial}
              </div>
            </div>
            <div
              className={
                !this.state.isScreenshareOn || this.state.isWhiteboardVisible
                  ? " centerInParenetInital  hidden"
                  : " centerInParenetInital stopScreenShareButton "
              }
            >
              <p> Your Screen Is Visible</p>
              <button onClick={this.stopScreenshare} type="button">
                Stop ScreenShare
              </button>
            </div>
            {this.isWhiteboardRequired() ? (
              <div
                className={
                  !this.state.isWhiteboardVisible
                    ? " centerInParenetInital  hidden"
                    : " centerInParenetInital  "
                }
                style={{ height: "90%", width: "99%", background: "#ffffff" }}
              >
                <iframe
                  ref={this.whiteboardFrame}
                  height="100%"
                  width="100%"
                  title="WhiteBoard"
                  src={this.whiteboardUrl()}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            ) : null}
            <video
              height="100%"
              playsInline
              muted
              autostart="true"
              className={
                !this.state.isVideoLoading &&
                this.state.mainVideoVisible &&
                !this.state.isScreenshareOn &&
                !this.state.isWhiteboardVisible
                  ? this.state.isMainVideoIsSS || !this.state.shouldStrechVideo
                    ? this.state.isMainVideoIsSS
                      ? "setUpVideoPlayer screenShareCss visible "
                      : "setUpVideoPlayer screenShareCss visible mirrorView "
                    : "setUpVideoPlayer  mirrorView visible "
                  : "setUpVideoPlayer hidden"
              }
              ref={this.mainVideoPlayer}
            ></video>
            {this.state.isStreamMuted ? (
              <div
                className={
                  this.state.mainVideoVisible &&
                  !this.state.isScreenshareOn &&
                  !this.state.isWhiteboardVisible
                    ? "loader  hidden "
                    : "loader hidden"
                }
                style={{
                  zIndex: "101",
                  left: "42%",
                  top: "40%",
                  position: "absolute",
                }}
              ></div>
            ) : null}
            {this.showGalleryView ? (
              <div
                style={{
                  position: "absolute",
                  height: "100%",
                  top: "0px",
                  zIndex: "1000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  overflow: "hidden",
                }}
              >
                {this.state.videoStreams.map((videoStream) => (
                  <GalleryViewBox
                    key={videoStream.participant.userId}
                    videoStream={videoStream}
                  />
                ))}
              </div>
            ) : null}
          </div>
          <canvas
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              height: "100%",
              width: "100%",
            }}
            className="hidden"
            height="480"
            width="720"
            ref={this.reordingCanvasRef}
          ></canvas>
          <video
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              height: "10%",
              width: "10%",
              zIndex: "-100",
            }}
            ref={this.selfVideoForRecording}
            height="480"
            width="720"
          ></video>

          <div className="btmcall">
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <div className="col-md-4">
           <div className="vdlefticn">
            <i className="fa fa-volume-up" aria-hidden="true" />
          </div>
        </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  className="vdcalicon"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  <ul
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      flexWrap: "wrap",
                    }}
                  >
                    {/* <li><a href="#"><i className="fa fa-arrows-alt" /></a></li> */}
                    {this.isRecordingRequired() ? (
                      <Tooltip
                        title={
                          this.state.recordingToggle
                            ? "Stop Recording"
                            : "Start Recording"
                        }
                      >
                        <li onClick={(e) => this.startStopRecording()}>
                          <a style={{ position: "relative" }}>
                            <div
                              class={`${
                                this.state.recordingToggle
                                  ? "pulsating-circle"
                                  : "non-pulsating-circle"
                              }`}
                            ></div>
                          </a>
                        </li>
                      </Tooltip>
                    ) : null}

                    {!isMobile && this.isScreenshareRequired() ? (
                      <Tooltip
                        title={
                          this.state.isScreenshareOn
                            ? "Screensharing Off"
                            : "Screensharing On"
                        }
                      >
                        <li>
                          <a
                            onClick={this.shareScreen}
                            style={{ position: "relative" }}
                          >
                            {this.state.isScreenshareOn ? (
                              <StopScreenShareIcon style={{ color: "#fff" }} />
                            ) : (
                              <ScreenShareIcon style={{ color: "white" }} />
                            )}
                          </a>
                        </li>
                      </Tooltip>
                    ) : null}

                    <Tooltip title={this.state.audioMuted ? "Unmute" : "Mute"}>
                      <li>
                        <a onClick={this.handleMuteButtonClick}>
                          <i
                            className={
                              this.state.audioMuted
                                ? "fa fa-microphone-slash white"
                                : "fa fa-microphone white"
                            }
                          />
                        </a>
                      </li>
                    </Tooltip>

                    <Tooltip title="End Meeting">
                      <li>
                        <a
                          style={{
                            backgroundColor: "red",
                            transform: "scale(1.2)",
                          }}
                          onClick={this.endMeeting}
                        >
                          <CallEndIcon style={{ color: "white" }} />
                        </a>
                      </li>
                    </Tooltip>

                    <Tooltip
                      title={this.state.videoPaused ? "Video On" : "Video Off"}
                    >
                      <li>
                        <a onClick={this.handleCameraButtonClick}>
                          {!this.state.videoPaused ? (
                            <VideocamIcon style={{ color: "white" }} />
                          ) : (
                            <VideocamOffIcon style={{ color: "white" }} />
                          )}
                        </a>
                      </li>
                    </Tooltip>
                    {!isMobile ? (
                      <Tooltip
                        title={
                          this.state.isChatWindowOpened
                            ? "Close Chat"
                            : "Open Chat"
                        }
                      >
                        <li onClick={(e) => this.openCloseChatWindow()}>
                          <a style={{ position: "relative" }}>
                            {!this.state.isNewMessage ? (
                              <ChatIcon style={{ color: "white" }}></ChatIcon>
                            ) : (
                              <ChatIcon style={{ color: "#de4343" }}></ChatIcon>
                            )}
                          </a>
                        </li>
                      </Tooltip>
                    ) : null}
                    <Tooltip
                      title={
                        this.state.isInFullScreen
                          ? "Exit Fullscreen"
                          : "Enter FullScreen"
                      }
                    >
                      <li onClick={this.fullScreen}>
                        <a>
                          {this.state.isInFullScreen ? (
                            <FullscreenExit
                              style={{ color: "#fff" }}
                            />
                          ) : (
                            <FullscreenIcon
                              style={{ color: "#ffff" }}
                            />
                          )}
                        </a>
                      </li>
                    </Tooltip>
                    <Tooltip title="More Options">
                      <li>
                        <a
                          onClick={(e) =>
                            this.setState({
                              showOptions: !this.state.showOptions,
                            })
                          }
                        >
                          <MoreVert style={{ color: "white" }} />
                        </a>
                      </li>
                    </Tooltip>

                    <div
                      className={`vh_options ${
                        this.state.showOptions ? "showOptions" : "hideOptions"
                      }`}
                    >
                      <div
                        className={`cross ${
                          this.state.showOptions ? "showCross" : "hideCross"
                        }`}
                        onClick={(e) => this.setState({ showOptions: false })}
                      >
                        <Close color="white" />
                      </div>
                      {/* <Tooltip title="Settings"> */}
                      <li
                        onClick={this.openSetting}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                        }}
                      >
                        <a style={{ background: "white" }}>
                          <SettingsIcon style={{ color: "rgb(0,122,254)" }} />
                        </a>
                        <div>Settings</div>
                      </li>
                      {/* </Tooltip> */}
                      {/* <Tooltip title={this.isCopyRequired() ? "Copy Invite" : "" }  > */}
                      <li
                        onClick={this.onCopyButtonTappedVideoHolder}
                        className={
                          this.isCopyRequired() ? null : " completeHide"
                        }
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                        }}
                      >
                        <a style={{ background: "white" }}>
                          <i
                            className="fa fa-clone"
                            style={{
                              color: "rgb(0,122,254)",
                              fontWeight: "900",
                            }}
                            aria-hidden="true"
                          />
                        </a>
                        <div>Copy Invite</div>
                      </li>
                      {/* </Tooltip> */}
                      {/* <Tooltip */}
                      {/* title={this.isWhiteboardRequired() ? (this.state.isWhiteboardVisible ? "Whiteboard Off" : "Whiteboard On" ) : "" }  > */}
                      <li
                        onClick={this.onWhiteBoardTapped}
                        className={
                          this.isWhiteboardRequired() ? null : " completeHide"
                        }
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                        }}
                      >
                        <a style={{ background: "white" }}>
                          <AssignmentIcon style={{ color: "rgb(0,122,254)" }} />
                        </a>
                        <div>
                          {this.state.isWhiteboardVisible
                            ? "Close Whiteboard"
                            : "Open Whiteboard"}
                        </div>
                      </li>
                      {/* </Tooltip> */}

                      <Tooltip
                        title={this.isSpeakerOff ? "Speaker On" : "Speaker Off"}
                      >
                        <li
                          onClick={this.onSpeakerOnOff}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            width: "100%",
                          }}
                        >
                          <a style={{ backgroundColor: "white" }}>
                            {this.state.isSpeakerOff ? (
                              <VolumeOffIcon
                                style={{ color: "rgb(0,122,254)" }}
                              />
                            ) : (
                              <VolumeUpIcon
                                style={{ color: "rgb(0,122,254)" }}
                              />
                            )}
                          </a>
                          <div>
                            {this.state.isSpeakerOff
                              ? "Speaker On"
                              : "Speaker Off"}
                          </div>
                        </li>
                      </Tooltip>
                      {/* <Tooltip title={this.state.isInFullScreen ? "Exit Fullscreen" : "Enter Fullscreen"} > */}

                      {/* </Tooltip> */}
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {this.state.audioStreams.map((audioStream) => (
            <AudioStream
              key={audioStream.participant.userId}
              audioStream={audioStream}
            />
          ))}
        </div>

        {this.state.showSetting ? (
          <Modal
            show={true}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={this.hideSetting}
          >
            <VideoSetting />
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default VideoHolder;
