import React from 'react';

import StartMeeting from './config/StartMeeting';
import MeetingBase from './MeetingBase';
import DuringCall from './duringCall/DuringCall';
import Disconnect from "./extra/Disconnect";
import Utility from './extra/Utility';
import Config from './extra/Config';


class Meeting extends MeetingBase{

  constructor(){
    super();
    this.state = {meetingStatus:"setup",duringCallKey : new Date().getTime()  };
    Utility.initGA();
  }

  componentDidMount() {
      super.addExtraListener("onConnected", () => {
        this.setState({meetingStatus:"started",duringCallKey : new Date().getTime()})
        Utility.logEvent({
          category: "Connected",
          action: 'User Id : ' + super.getMeetingRequest().getUserId,
          value: 'Room Id : ' + super.getMeetingRequest().getRoomId
        });
      })
      super.addExtraListener("onDisconnect", (data) => {
        this.setState({meetingStatus : "disconnect"})
      })
  } 

  render(){
    //  return
//       <div className="main_wrapper" style = {{minHeight:'90vh'}}>

//        {this.state.meetingStatus === "setup" ? <StartMeeting />       <p style= {{textAlign: 'center'}}>Powered By <a href={Config.getPowredByLink(Utility.getAppId())} target="_blank">{Config.getPowredByName(Utility.getAppId())}</a></p>
//  : <DuringCall  key= {this.state.duringCallKey}></DuringCall> }
      // </div>
      if(this.state.meetingStatus === "setup"){
        return  <div className="main_wrapper" style = {{minHeight:'90vh'}}> <StartMeeting />
        <p style= {{textAlign: 'center'}}>Powered By <a href={Config.getPowredByLink(Utility.getAppId())} target="_blank">{Config.getPowredByName(Utility.getAppId())}</a></p>
        </div>

      }
      else if(this.state.meetingStatus === "disconnect"){
        return <Disconnect ></Disconnect>
      }
      else{
        return  <DuringCall  key= {this.state.duringCallKey}></DuringCall>

      }
    

    }
}

export default Meeting;