import MeetingBase from "../../MeetingBase";
import SingleChatMessage from "./SingleChatMessage";
import React from "react";
import MeetingHandler from "../../MeetingHandler";
import Utility from "../../extra/Utility";
import S3 from "react-aws-s3";
import Config from "../../extra/Config";
import axios from "axios";
class ChatPanel extends MeetingBase {
  constructor() {
    super();

    let messages = MeetingHandler.getInstance().getChatMessages();
    this.file = null;
    this.state = { messages: messages, file: null, loading: false };
    this.onSendMessageTapped = this.onSendMessageTapped.bind(this);
    this.addNewMessage = this.addNewMessage.bind(this);
    this.onInputTextKeyDown = this.onInputTextKeyDown.bind(this);
    this.messagesEndRef = React.createRef();
    this.messageInput = React.createRef();
    this.roomId = MeetingHandler.getInstance().getMeetingRequest().roomId;
    this.s3config = {
      ...Config.getS3CredentialByName(Utility.getAppId(), this.roomId),
    };
    this.s3Client = new S3(this.s3config);
    this.onS3SignedUrlForDownload = this.onS3SignedUrlForDownload.bind(this)
    this.onS3SignedUrlForUpload = this.onS3SignedUrlForUpload.bind(this)

  }

  componentDidMount() {
    this.scrollToBottom();
    super.addExtraListenerWithForcefullyAdded("onS3PreSignedUrlForUpload",this.onS3SignedUrlForUpload,true)
    super.addExtraListenerWithForcefullyAdded("onS3PreSignedUrlForDownload",this.onS3SignedUrlForDownload,true)

  }

  componentDidUpdate() {
    this.scrollToBottom();

  }
  

  onS3SignedUrlForUpload(data){
    console.log(data);
    let signedUrl = data.signedUrl;
    var options = {
      headers: {
        'Content-Type': this.file.type
      }
    };
    console.log(options);
    axios.put(signedUrl,this.file,options).then(response => {
      super.getMeetingHandler().getS3PreSignedUrlForDownload(this.file.name)

    }, response => {
      console.log(response);
    })
    

  }

  async onS3SignedUrlForDownload(data){

    let file = data.signedUrl;
    const roomIdFromUrl = Utility.getRoomIdFromUrl();

    let msgObj = super
              .getMeetingHandler()
              .newMessageObject("all", data.url);
            msgObj["type"] = "file";
            msgObj.extraData = {downloadUrl : file}
            let result = super.getMeetingHandler().sendMessage(msgObj);
            if (result.isSuccess) {
              this.setState({ loading: false });
              this.addNewMessage(msgObj);
              if (Utility.getAppId() === "teledental") {
                try {
                  let config = {
                    headers: {
                      "Content-Type": "application/json",
                      "meet-signature": "6dedf61b-c779-4c72-9e1b-fc66e8f254d6",
                    },
                  };

                  const response = await axios.post(
                    `https://teledental.com/api/v1/sessions/${roomIdFromUrl}/attachment`,
                    {
                      key: data.url,
                    },
                    config
                  );
                  console.log(response.data);
                } catch (error) {
                  console.log(error.response.data);
                }
              }
                if (Utility.getAppId() === "dentalchat") {
                  try {
                    let config = {
                      headers: {
                        "Content-Type": "application/json",
                        "meet-signature":
                          "6dedf61b-c779-4c72-9e1b-fc66e8f254d6",
                      },
                    };

                    const response = await axios.post(
                      `https://blog.dentalchat.com/server/api/meeting/${roomIdFromUrl}/attachments`,
                      {
                        key: data.url,
                      },
                      config
                    );
                    console.log(response.data);
                  } catch (error) {
                    console.log(error.response.data);
                  }
              }
            }
    console.log(data);

  }

  onInputTextKeyDown(e) {
    if (e.key === "Enter") {
      this.onSendMessageTapped();
    }
  }

  onSendMessageTapped() {
    var messageInputValue = this.messageInput.current.value;
    if (messageInputValue != null && messageInputValue.length > 0) {
      let messageObject = super
        .getMeetingHandler()
        .newMessageObject("all", this.messageInput.current.value);
      let output = super.getMeetingHandler().sendMessage(messageObject);
      if (output !== null) {
        if (output.isSuccess) {
          this.addNewMessage(messageObject);
        } else {
          Utility.showAlertOnOutputError(
            output,
            super.getMeetingRequest().getUserId
          );
        }
      }
      this.messageInput.current.value = "";
    }
  }

  onFileUpload = async (e) => {

    if(Utility.getAppId() === "teledental" || Utility.getAppId() === "dentalchat"){
      if (!e.target.files.length) {
        return;
      }
      this.setState({ loading: true });
      this.file = e.target.files[0]; 
      super.getMeetingHandler().getS3PreSignedUrlForUpload(e.target.files[0].name,e.target.files[0].type)
      return;

    }

    const roomIdFromUrl = Utility.getRoomIdFromUrl();
    if (e.target.files.length) {
      if (e.target.files[0].size / 1024 / 1024 > 15) {
        console.log("file size exceeded");
        return;
      } else {
        this.setState({ loading: true });
        this.s3Client
          .uploadFile(e.target.files[0], e.target.files[0].name)
          .then(async (res) => {
            const file = res.location;

            let msgObj = super
              .getMeetingHandler()
              .newMessageObject("all", encodeURI(file));
            msgObj["type"] = "file";

            let result = super.getMeetingHandler().sendMessage(msgObj);
            if (result.isSuccess) {
              this.setState({ loading: false });
              this.addNewMessage(msgObj);
              if (Utility.getAppId() === "teledental") {
                try {
                  let config = {
                    headers: {
                      "Content-Type": "application/json",
                      "meet-signature": "6dedf61b-c779-4c72-9e1b-fc66e8f254d6",
                    },
                  };

                  const response = await axios.post(
                    `https://teledental.com/api/v1/sessions/${roomIdFromUrl}/attachment`,
                    {
                      key: file,
                    },
                    config
                  );
                  console.log(response.data);
                } catch (error) {
                  console.log(error.response.data);
                }
              }
                if (Utility.getAppId() === "dentalchat") {
                  try {
                    let config = {
                      headers: {
                        "Content-Type": "application/json",
                        "meet-signature":
                          "6dedf61b-c779-4c72-9e1b-fc66e8f254d6",
                      },
                    };

                    const response = await axios.post(
                      `https://blog.dentalchat.com/server/api/meeting/${roomIdFromUrl}/attachments`,
                      {
                        key: file,
                      },
                      config
                    );
                    console.log(response.data);
                  } catch (error) {
                    console.log(error.response.data);
                  }
              }
            } else {
              Utility.showAlertOnOutputError(
                result,
                super.getMeetingRequest().getUserId
              );
            }
          })
          .catch((err) => {
            console.log("something went wrong");
            console.log(err);
          });
      }
    } else {
      this.setState({ loading: false });
      console.log("caceled");
      return;
    }
  };

  addNewMessage(message) {
    let allMessages = MeetingHandler.getInstance().addNewChatMessage(message);
    this.setState({ messages: allMessages });
  }

  scrollToBottom = () => {
    if (this.props.shouldShowMessages) {
      this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
    this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    console.log(this.state.file);
    return (
      <div
        id="home"
        className="tab-pane active"
        style={{ overflowY: "scroll", overflowX: "hidden", height: "55vh" }}
      >
        <br />
        {this.state.messages.map((message, index) => (
          <SingleChatMessage key={index} message={message} loading={false} />
        ))}
        {this.state.loading && (
          <SingleChatMessage message={""} loading={true} />
        )}
        <div ref={this.messagesEndRef} className="scrolltobottom"></div>
        <div className="semdmsg mt-4">
          <div className="input_box">
            <label className="file_label">
              <input
                type="file"
                className="file_input"
                onChange={(e) => this.onFileUpload(e)}
              />
              <i className="fa fa-paperclip attachIcon" aria-hidden="true" />
            </label>
            <div className="input_group">
              <input
                onKeyDown={this.onInputTextKeyDown}
                ref={this.messageInput}
                type="text"
                className="input_append"
                placeholder="Write Your Message"
                style={{}}
              />
              <button
                onClick={this.onSendMessageTapped}
                className="send_btn"
                type="submit"
              >
                <i className="fa fa-paper-plane" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChatPanel;
