import MeetingHandler from "../MeetingHandler";
import ReactGA from "react-ga";
import Config from "./Config";
var jwt = require("jsonwebtoken");

const queryString = require("query-string");

class Utility {
  static copyLink() {
    var msg =
      MeetingHandler.getInstance().getMeetingRequest().getUserData["name"] +
      Config.getCopyLinkMsg(
        MeetingHandler.getInstance().getMeetingRequest().appId,
        MeetingHandler.getInstance().getMeetingRequest().isAdmin
      );
    let url =
      window.location.origin +
      "?vm=" +
      MeetingHandler.getInstance().getMeetingRequest().getRoomId;
    msg = msg + url + " to join";
    navigator.clipboard.writeText(msg);
  }

  static getRoomIdFromUrl() {
    const queryParmas = queryString.parse(window.location.search);
    var roomId = null;
    if (queryParmas.vm && queryParmas.vm !== null) {
      roomId = queryParmas.vm;
    } else if (queryParmas.token && queryParmas.token !== null) {
      let tokenData = Utility.decodeJWTToken(queryParmas.token);
      if (tokenData !== null && tokenData.hasOwnProperty("meetingId")) {
        return tokenData["meetingId"];
      }
    }
    return roomId;
  }

  static getToken(){
    const queryParmas = queryString.parse(window.location.search);
    if (queryParmas.token && queryParmas.token != null) {
      return queryParmas.token;
    }
    return null;
  }

  static getUserIdFromUrl() {
    const queryParmas = queryString.parse(window.location.search);
    var userId = null;
    if (queryParmas.token && queryParmas.token != null) {
      let tokenData = Utility.decodeJWTToken(queryParmas.token);
      if (tokenData !== null && tokenData.hasOwnProperty("userId")) {
        return tokenData["userId"];
      }
    }
    return new Date().getTime() + "_" + Math.floor(Math.random() * 10);
  }

  static getUserDataFromUrl() {
    const queryParmas = queryString.parse(window.location.search);
    var userId = null;
    if (queryParmas.token && queryParmas.token != null) {
      let tokenData = Utility.decodeJWTToken(queryParmas.token);
      return tokenData;
    }

    return {};
  }

  static getAppId() {
    const queryParmas = queryString.parse(window.location.search);
    var userId = null;
    let tokenData = Utility.decodeJWTToken(queryParmas.token);
    if (tokenData !== null && tokenData.hasOwnProperty("app_id")) {
      return tokenData["app_id"];
    }
    // return "uhc";
    // return "demo";
    // return "xpertflix";
    // return    "tesing";
    // return "trail";
    // return "demo";
    return "dentalchat";

  }

  static isAdmin() {
    const queryParmas = queryString.parse(window.location.search);
    var userId = null;
    if (queryParmas.token != null) {
      let tokenData = Utility.decodeJWTToken(queryParmas.token);
      if (
        tokenData !== null &&
        tokenData.hasOwnProperty("role") &&
        tokenData["role"] === "teacher"
      ) {
        return true;
      }
    }

    return false;
  }

  static shouldLogToGA() {
    if (
      window.location.origin.includes("demo.") ||
      window.location.origin.includes("liveclass.")
    ) {
      return true;
    }
    return true;
  }

  static initGA() {
    if (this.shouldLogToGA()) {
      ReactGA.initialize(Config.getGAId(Utility.getAppId()));
    }
  }

  static logEvent(data) {
    if (this.shouldLogToGA()) {
      ReactGA.event(data);
    }
  }

  static decodeJWTToken(token) {
    var decoded = jwt.decode(token);
    return decoded;
  }

  static showAlertOnOutputError(output, userId) {
    if (output.status === "Permission_Failure") {
      alert(
        Config.errorMsgOnPermissionDenied(
          MeetingHandler.getInstance().getMeetingRequest().appId,
          MeetingHandler.getInstance().getMeetingRequest().isAdmin
        )
      );
    } else {
      alert(output.message);
    }
  }
}

export default Utility;
