import MeetingBase from "../../MeetingBase";
import React from "react";
import SignalWifiOffIcon from "@material-ui/icons/SignalWifiOff";
import VisibilitySensor from "react-visibility-sensor";

// import {VisibilitySensor} from 'react-visibility-sensor';

class VideoStream extends MeetingBase {
  constructor(props) {
    super(props);
    this.videoStream = props.videoStream;
    this.videoPlayer = React.createRef();
    this.currentTrack = null;
    // this.onTrack = this.onTrack.bind(this);
    this.refershTrack = this.refershTrack.bind(this);
    this.onAudioVideoStatusUpdated = this.onAudioVideoStatusUpdated.bind(this);
    this.onStreamStatusUpdated = this.onStreamStatusUpdated.bind(this);
    this.onUserMuteOrEnded = this.onUserMuteOrEnded.bind(this);
    this.onUserUnmuted = this.onUserUnmuted.bind(this);
    this.onVisiblityChange = this.onVisiblityChange.bind(this);

    let name = this.videoStream.participant.userData.name;
    var initial = name.charAt(0);
    this.state = {
      mainVideoVisible: true,
      isVideoStarted: false,
      initial: initial,
      shouldShowMirrorView: true,
      isStreamHasNetworkIssue: !this.videoStream.isStremPresent,
      isStreamMuted: false,
    };
  }
  componentDidMount() {
    super.addExtraListenerWithForcefullyAdded(
      "audioVideoStatusUpdated",
      this.onAudioVideoStatusUpdated,
      true
    );
    super.addExtraListenerWithForcefullyAdded(
      "refershTrack",
      this.refershTrack,
      true
    );
    // super.addExtraListenerWithForcefullyAdded('onTrack', this.onTrack,true)
    super.addExtraListenerWithForcefullyAdded(
      "streamStatusUpdated",
      this.onStreamStatusUpdated,
      true
    );

    this.updateAndShowVideoStream();

    console.log("New Track " + this.videoStream.participant.userId);
  }

  refershTrack(data) {
    if (data.track.kind === "video") {
      if (
        this.videoStream != null &&
        data.participant.userId === this.videoStream.participant.userId &&
        data.track !== null
      ) {
        if (
          this.currentTrack !== null &&
          this.currentTrack.id === data.track.id
        ) {
          return;
        }
        if (this.videoStream !== null && this.videoStream.track !== null) {
          try {
            this.videoStream.track.removeEventListener(
              "ended",
              this.onUserMuteOrEnded
            );
            this.videoStream.track.removeEventListener(
              "mute",
              this.onUserMuteOrEnded
            );
            this.videoStream.track.removeEventListener(
              "unmute",
              this.onUserUnmuted
            );
          } catch (err) {}
        }
        this.videoStream = data;

        this.updateAndShowVideoStream();
      }
    }
  }
  updateAndShowVideoStream() {
    if (this.videoStream != null) {
      let track = this.videoStream.track;
      if (track !== null) {
        if (this.currentTrack !== null && this.currentTrack.id === track.id) {
          return;
        }
        this.currentTrack = track;
        let mediStream = new MediaStream();
        mediStream.addTrack(track);
        this.videoPlayer.current.load();
        this.videoPlayer.current.srcObject = mediStream;
        this.videoPlayer.current.play();
        this.setState({ isStreamMuted: track.muted });

        try {
          track.addEventListener("ended", this.onUserMuteOrEnded);
          track.addEventListener("mute", this.onUserMuteOrEnded);
          track.addEventListener("unmute", this.onUserUnmuted);
        } catch (err) {}
      }
      if (this.videoStream.cameraType === "back") {
        this.setState({
          mainVideoVisible:
            this.videoStream.participant.isVideoEnable && track !== null,
          shouldShowMirrorView: false,
        });
      } else {
        this.setState({
          mainVideoVisible:
            this.videoStream.participant.isVideoEnable && track !== null,
          shouldShowMirrorView: true,
        });
      }
    }
  }

  onUserMuteOrEnded() {
    console.log("onUserMuteOrEnded");
    this.setState({ isStreamMuted: true });
  }
  onUserUnmuted() {
    console.log("onUserUnmuted");
    this.setState({ isStreamMuted: false, isVideoStarted: true });
  }

  // onTrack(data){
  //   if(data.isLocalTrack === false || data.kind === "audio"){
  //     return;
  //   }
  //   let userId = data.participant.userId

  //   if(this.videoStream != null  && userId === this.videoStream.participant.userId){
  //     this.videoStream  = data;
  //     this.updateAndShowVideoStream()

  //   }
  // }

  onAudioVideoStatusUpdated(participant) {
    let userId = participant.userId;
    if (
      this.videoStream != null &&
      this.videoStream.videoType !== "SS" &&
      userId === this.videoStream.participant.userId
    ) {
      if (this.state.mainVideoVisible === participant.isVideoEnable) {
        return;
      }
      this.setState({ mainVideoVisible: participant.isVideoEnable });
      if (
        this.videoStream.hasOwnProperty("track") &&
        this.videoStream.track !== null
      ) {
        this.videoStream.track.enabled = participant.isVideoEnable;
      }
    }
  }

  onStreamStatusUpdated(track) {
    if (track.participant.userId === this.videoStream.participant.userId) {
      this.setState({ isStreamHasNetworkIssue: !track.isStremPresent });
    }
  }

  componentWillUnmount() {
    super.removeExtraListener(
      "audioVideoStatusUpdated",
      this.onAudioVideoStatusUpdated
    );
    super.removeExtraListener("refershTrack", this.refershTrack);
    // super.removeExtraListener('onTrack', this.onTrack)
    super.removeExtraListener(
      "streamStatusUpdated",
      this.onStreamStatusUpdated
    );
  }

  onVisiblityChange(isVisible) {
    console.log("onVisiblityChange " + isVisible);
    if (isVisible) {
      super.getMeetingHandler().resumeTrackInScreen(this.videoStream);
    } else {
      super.getMeetingHandler().pauseTrackOutOfScreen(this.videoStream);
      this.setState({ isVideoStarted: false });
    }
  }

  render() {
    return (
      <div>
        <VisibilitySensor onChange={this.onVisiblityChange}>
          <li className="justify_align_flex" style={{ position: "relative" }}>
            <span
              className="justify_align_flex"
              style={{ height: "100%", position: "relative" }}
            >
              {this.isStreamHasNetworkIssue ? (
                <SignalWifiOffIcon
                  style={{
                    fontSize: 20,
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                    zIndex: "99",
                  }}
                />
              ) : null}

              {!this.state.mainVideoVisible || !this.state.isVideoStarted ? (
                <div
                  className="justify_align_flex"
                  style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50% , -50%)",
                    position: "absolute",
                  }}
                >
                  <div
                    className="inital "
                    style={{
                      height: "40px",
                      width: "40px",
                      lineHeight: "40px",
                      borderRadius: "100%",
                    }}
                  >
                    {this.state.initial}
                  </div>
                </div>
              ) : null}

              <video
                height="100%"
                playsInline
                muted
                autostart="true"
                className={
                  this.state.mainVideoVisible
                    ? this.state.shouldShowMirrorView
                      ? "setUpVideoPlayer visible mirrorView"
                      : "setUpVideoPlayer visible "
                    : "setUpVideoPlayer hidden"
                }
                ref={this.videoPlayer}
              ></video>
              {this.state.isStreamMuted ? (
                <div
                  className={
                    this.state.mainVideoVisible &&
                    !this.state.isScreenshareOn &&
                    !this.state.isWhiteboardVisible
                      ? "loader  hidden "
                      : "loader hidden"
                  }
                  style={{
                    zIndex: "101",
                    left: "35%",
                    top: "35%",
                    position: "absolute",
                    height: "22px",
                    width: "22px",
                  }}
                ></div>
              ) : null}
            </span>
          </li>
        </VisibilitySensor>
      </div>
    );
  }
}

export default VideoStream;
