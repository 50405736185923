import  MeetingBase  from "../MeetingBase";
import React from 'react';

import CameraMicrophoneOptionSelector from "./CameraMicrophoneOptionSelector";
import Utility from "../extra/Utility";
class ConfigPlayer extends MeetingBase{
  constructor(){
    super();
    this.state = {isVideoVisible : false , isMute : false , isVideoOn : true ,shouldShowMirrorView : true} 
    this.videoPlayer = React.createRef();
    this.handleMuteButtonClick = this.handleMuteButtonClick.bind(this);
    this.handleCameraButtonClick = this.handleCameraButtonClick.bind(this);
    this.onTrack = this.onTrack.bind(this);

    
  }

  onTrack (track) {
    if(track.isLocalTrack && track.kind === "video"){
      let mediaStream  = new MediaStream();
      mediaStream.addTrack(track.track);
      this.videoPlayer.current.srcObject = mediaStream;
      this.videoPlayer.current.play();
      if(track.cameraType === "back"){
        this.setState({isVideoVisible: track.track.enabled , shouldShowMirrorView : false})

      }
      else{
        this.setState({isVideoVisible: track.track.enabled, shouldShowMirrorView : true})

      }
    }
  }
  componentDidMount() {
    super.addExtraListener('onTrack', this.onTrack)
    super.addExtraListener('refershTrack', this.onTrack)

    if(super.getMeetingRequest().defaultMicEnable === false){
      this.setState(
        {isMute : true}
        );
    }
    if(super.getMeetingRequest().defaultVideoEnable === false){
      this.setState(
        {isVideoOn : false , isVideoVisible : false}
        );
    }
    super.getMeetingHandler().startLocalStream(true,true)
    
  }

  componentWillUnmount(){
    super.removeExtraListener('onTrack', this.onTrack)
    super.removeExtraListener('refershTrack', this.onTrack)

  }
  handleOutput(output,isForAudio,isForVideo){
    if(output !== null){

      if(output.isSuccess){
        if(isForAudio){
          this.setState(
            {isMute : !this.state.isMute}
            );
        }
        if(isForVideo){
          this.setState(
            {isVideoOn : !this.state.isVideoOn , isVideoVisible : false}
            );
        }
      }
      else{
        Utility.showAlertOnOutputError(output,null)
      }
    }
  }
  handleMuteButtonClick(){
    if(this.state.isMute){
      super.getMeetingHandler().unmute(super.getMeetingRequest().getUserId).then(

        function(output) {
          this.handleOutput(output,true,false)}.bind(this),
        function(error) {}
      );;
    }
    else{
      super.getMeetingHandler().muteUser(super.getMeetingRequest().getUserId).then(

        function(output) {
          this.handleOutput(output,true,false)}.bind(this),
        function(error) {}
      );;
    }

    
  }

  handleCameraButtonClick(){
    let isVideoOn = this.state.isVideoOn;
    if(isVideoOn){
      super.getMeetingHandler().pauseCamera(super.getMeetingRequest().getUserId).then(

        function(output) {
          this.handleOutput(output,false,true)}.bind(this),
        function(error) {}
      );

    
    }
    else{
      super.getMeetingHandler().resumeCamera(super.getMeetingRequest().getUserId).then(

        function(output) {
          this.handleOutput(output,false,true)}.bind(this),
        function(error) {}
      )

    
    }
   
  }
    
    render(){

   return    <div className="col-md-6"><div className="videopart shadow" style = {{ position:'relative' , height:'350px'}}>
{this.state.isVideoVisible ? null :<div className="usericon">
          <i className="fa fa-user" aria-hidden="true" />
 </div>}

<video playsInline muted autostart="true"  className = {this.state.isVideoOn ? (this.state.shouldShowMirrorView ? "setUpVideoPlayer visible mirrorView" : "setUpVideoPlayer visible "  ):  "setUpVideoPlayer hidden"}ref={this.videoPlayer}  ></video> 


 <div className="vdbotm d-flex justify-content-between" style = {{width: '100%'}}>
   <div className="vdrighticn">
       <a onClick={this.handleMuteButtonClick} ><i className={this.state.isMute ? "fa fa-microphone-slash white" : "fa fa-microphone white"}  /></a>
       <a onClick={this.handleCameraButtonClick}>
       {this.state.isVideoOn ?
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" className="bi bi-camera-video-fill" viewBox="0 0 16 16">
         <path fillRule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z"/>
       </svg>
        :
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" className="bi bi-camera-video-off-fill" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l6.69 9.365zm-10.114-9A2.001 2.001 0 0 0 0 5v6a2 2 0 0 0 2 2h5.728L.847 3.366zm9.746 11.925l-10-14 .814-.58 10 14-.814.58z"/>
</svg>}</a>   
   </div>
 </div>
</div>
<CameraMicrophoneOptionSelector/>
</div>
}
}

export default ConfigPlayer;
