import MeetingBase from "../../MeetingBase";
import MeetingHandler from "../../MeetingHandler";
import { InsertDriveFile } from "@material-ui/icons";

class SingleChatMessage extends MeetingBase {
  constructor(props) {
    super(props);
    this.messagePayload = props.message;
    this.name = "Unknown";
    this.senderUserId = this.messagePayload.sender;

    let participant = MeetingHandler.getInstance().participantByUserId(
      this.senderUserId
    );
    if (participant !== null) {
      if (
        participant.userData.name != null &&
        participant.userData.name.length > 0
      ) {
        this.name = participant.userData.name;
      }
    }

    this.messageText = this.messagePayload.getMessage;
    this.initial = this.name.charAt(0);
    this.replaceURLs = this.replaceURLs.bind(this)
    this.isSelfMessage = this.senderUserId === super.getMeetingRequest().userId;
  }

  componentDidMount() {}

  replaceURLs(message, props) {
    if (!message) return;

    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      console.log(this.messagePayload);
      if(this.messagePayload && this.messagePayload.extraData && this.messagePayload.extraData.downloadUrl){
        hyperlink = this.messagePayload.extraData.downloadUrl;
      }
      if (!hyperlink.match("^https?://")) {
        hyperlink = "http://" + hyperlink;
      }
      let urlSplit;
      let fileName;
      if (props.message.type === "file") {
        urlSplit = url.split("/");
        fileName = urlSplit[urlSplit.length - 1];
        return (
          '<a href="' +
          hyperlink +
          '" target="_blank" rel="noopener noreferrer">' +
          fileName +
          "</a>"
        );
      } else {
        return (
          '<a href="' +
          hyperlink +
          '" target="_blank" rel="noopener noreferrer">' +
          url +
          "</a>"
        );
      }
    }.bind(this));
  }
  render() {
    if (this.props.loading) {
      return (
        <div className="you">
          <div className="youbox">
            <img src="/loader.svg" height={40} />
          </div>
        </div>
      );
    }
    if (this.isSelfMessage) {
      return (
        <div className="you">
          <div className="youbox">
            {this.props.message.type === "file" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <InsertDriveFile fontSize="medium" />
                <p
                  style={{ marginLeft: 5 }}
                  dangerouslySetInnerHTML={{
                    __html: this.replaceURLs(this.messageText, this.props),
                  }}
                />
              </div>
            ) : (
              <p
                dangerouslySetInnerHTML={{
                  __html: this.replaceURLs(this.messageText, this.props),
                }}
              />
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="chatbox d-flex">
          {console.log("this.props.message", this.props.message)}

          <div
            className="inital"
            style={{
              height: "20px",
              width: "20px",
              lineHeight: "20px",
              marginRight: "5px",
            }}
          >
            {this.initial}
          </div>
          <div className="chattext">
            <h5>{this.name}</h5>
            <div className="chtxbox">
              {this.props.message.type === "file" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <InsertDriveFile fontSize="medium" />
                  <p
                    style={{ marginLeft: 5 }}
                    dangerouslySetInnerHTML={{
                      __html: this.replaceURLs(
                        this.props.message.message,
                        this.props
                      ),
                    }}
                  />
                </div>
              ) : (
                <p
                dangerouslySetInnerHTML={{
                  __html: this.replaceURLs(this.props.message.message, this.props),
                }}
              />
                // <p>{this.props.message.message}</p>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}
export default SingleChatMessage;
