import MeetingBase from "../MeetingBase";
import LeftPanel from "./leftPanel/LeftPanel";
import RightPanel from "./rightPanel/RightPanel";
import { Modal, Button } from "react-bootstrap";
import { isMobile, isMobileOnly } from "react-device-detect";
import MeetingHandler from "../MeetingHandler";
import {
  Cancel,
  ChatBubble,
  ChatBubbleOutlineRounded,
  Close,
} from "@material-ui/icons";
import Config from "../extra/Config";
import Utility from "../extra/Utility";

class DuringCall extends MeetingBase {
  constructor() {
    super();

    this.state = {
      shouldShowRightWindow: false,
      shouldShowLeftWindow: true,
      showChatAndParticipant: Config.byDefaultMessageOpen(
        super.getMeetingRequest().appId
      ),
    };

    this.onLeftWindowTapped = this.onLeftWindowTapped.bind(this);
    this.onRightWindowTapped = this.onRightWindowTapped.bind(this);
  }
  componentDidMount() {
    if (isMobile === false) {
      this.setState({ shouldShowRightWindow: true });
    }

    MeetingHandler.getInstance().subscribeToLocalEvent(
      "openCloseChat",
      function (shouldOpen) {
        if (shouldOpen.shouldOpen) {
          this.setState({ showChatAndParticipant: true });
        } else {
          this.setState({ showChatAndParticipant: false });
        }
      }.bind(this)
    );
  }

  onLeftWindowTapped() {
    MeetingHandler.getInstance().emitLocalEvent("onTapSwitch", {
      isVideoTab: true,
    });
    this.setState({ shouldShowRightWindow: false, shouldShowLeftWindow: true });
  }
  onRightWindowTapped() {
    MeetingHandler.getInstance().emitLocalEvent("onTapSwitch", {
      isVideoTab: false,
    });
    this.setState({ shouldShowRightWindow: true, shouldShowLeftWindow: false });
  }
  render() {
    return (
      <section className="main_wrapper" style={{ minHeight: "100vh" }}>
        <div className="container">
          <div
            className="mainboxes"
            style={{ height: "90%", position: "relative", marginBottom: "0px" }}
          >
            {!isMobileOnly && this.state.showChatAndParticipant&& (
              <div
                className={`chatToggle ${
                  this.state.showChatAndParticipant ? "whitebg" : "transparent"
                }`}
                onClick={(e) =>
                  this.setState({
                    showChatAndParticipant: !this.state.showChatAndParticipant,
                  })
                }
              >
                {this.state.showChatAndParticipant ? (
                  <Close />
                ) : (
                  null
                )}
              </div>
            )}
            <div
              className="row"
              style={{
                height: "90%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                padding: "0px",
              }}
            >
              <div
                className={`leftpanel ${
                  this.state.showChatAndParticipant ? "col-md-8" : "col-md-12"
                }`}
              >
                <LeftPanel />
              </div>
              <div
                className={`rightpanel ${
                  this.state.showChatAndParticipant === false &&
                  isMobileOnly === false
                    ? "not-col"
                    : isMobileOnly === true
                    ? "col-md-12"
                    : "col-md-4"
                }`}
              >
                <RightPanel />
              </div>
              {/* {this.state.shouldShowLeftWindow  ? : null} */}
              {/* {this.state.shouldShowRightWindow  ? : null} */}

              {isMobile && (
                <div
                  style={{
                    position: "fixed",
                    padding: "10px",
                    zIndex: "999999",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    bottom: "0px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    left: "0px",
                    backgroundColor: "white",
                    justifyContent: "center",
                  }}
                >
                  <ul
                    className="nav nav-tabs"
                    role="tablist"
                    style={{ width: "100%" }}
                  >
                    <li
                      className="nav-item"
                      style={{ cursor: "pointer", flex: "0.5" }}
                    >
                      <a
                        onClick={this.onLeftWindowTapped}
                        className={
                          this.state.shouldShowLeftWindow
                            ? "nav-link  active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        style={{ textAlign: "center" }}
                      >
                        Video
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      style={{ cursor: "pointer", flex: "0.5" }}
                    >
                      <a
                        onClick={this.onRightWindowTapped}
                        className={
                          this.state.shouldShowRightWindow
                            ? "nav-link  active"
                            : "nav-link"
                        }
                        style={{ textAlign: "center" }}
                        data-toggle="tab"
                      >
                        Message
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          <p style={{ textAlign: "center", bottom: "10px" }}>
            Powered By{" "}
            <a
              href={Config.getPowredByLink(Utility.getAppId())}
              target="_blank"
            >
              {Config.getPowredByName(Utility.getAppId())}
            </a>
          </p>
        </div>
      </section>
    );
  }
}

export default DuringCall;
