import React from 'react';

import MeetingHandler from "./MeetingHandler";

class MeetingBase extends React.Component{

  constructor(){
    super();
    this.meetingRequest = null;
  }

    getMeetingHandler(){
        return MeetingHandler.getInstance().getMeetingHandler();
    }

    getMeetingRequest(){
      
      return MeetingHandler.getInstance().getMeetingRequest();
    }

    addExtraListener(event , listner){
        if(MeetingHandler.getInstance().getMeetingHandler() != null){
          if(MeetingHandler.getInstance().getMeetingHandler().eventEmitter.listenerCount(event) > 0){
            return;
          }
          MeetingHandler.getInstance().getMeetingHandler().eventEmitter.on(event,listner);      
      
        }
      }
    addExtraListenerWithForcefullyAdded(event , listner,forceFullyAdd){
      if(forceFullyAdd === false){
        this.addExtraListener(event,listner)
        return;
      }
      MeetingHandler.getInstance().getMeetingHandler().eventEmitter.on(event,listner);      

      }

    removeExtraListener(event,listner){
        MeetingHandler.getInstance().getMeetingHandler().eventEmitter.off(event,listner)
    }
}

export default MeetingBase;